import { Button } from '@arckit/ui'
import { Col } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import { StateReview } from 'pages/state-reviews'
import React from 'react'
import { Collapse } from 'react-collapse'

interface Props {
	data: StateReview
}

interface State {
	boxOpen: boolean
}

class ReviewsCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { boxOpen: false }
	}

	handleClick = () => {
		this.setState({
			boxOpen: !this.state.boxOpen
		})
	}

	render() {
		return (
			<Col width="w-full md:w-1/2" className="mb-7">
				<div className="relative h-full rounded bg-white shadow-md">
					<div
						className="flex h-40 items-end justify-end rounded-t bg-cover bg-no-repeat px-4 py-2"
						style={{ backgroundImage: `url(${this.props.data.image.file.url})` }}
					>
						{/* <img src={this.props.data.stateMapSvg.file.url} alt={`${this.props.data.name} map outline`} style={{ width: 80, maxHeight: 80 }} /> */}
						<span className="text-4xl font-semibold tracking-wide text-white">{this.props.data.name}</span>
					</div>
					<div className="p-6">
						<P size="md" className="mb-3">
							{this.props.data.reviewText1}
						</P>
						<a href={this.props.data.reviewLink1} target="_blank" rel="noopener noreferrer">
							<Button>{this.props.data.reviewButton1}</Button>
						</a>
					</div>
					{this.props.data.reviewText2 && (
						<button
							className="absolute mb-4 flex items-center justify-center rounded-full bg-gray-200 p-2 text-gray-800 hover:bg-gray-300 focus:outline-none"
							onClick={this.handleClick}
							style={{ bottom: 0, right: 20 }}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className={`h-4 w-4 ${this.state.boxOpen ? 'rotate-180' : 'rotate-0'}`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button>
					)}
					<Collapse
						isOpened={this.state.boxOpen}
						theme={{
							collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
							content: 'ReactCollapse--content'
						}}
					>
						{this.props.data.reviewLink2 && (
							<div className="p-6">
								<P size="md" className="mb-3">
									{this.props.data.reviewText2}
								</P>
								<a href={this.props.data.reviewLink2} target="_blank" rel="noopener noreferrer">
									<Button>{this.props.data.reviewButton2}</Button>
								</a>
							</div>
						)}
						{/* {this.props.data.reviewLink3 && (
							<div className="p-6">
								<p className="text-lg text-slate-400">{this.props.data.reviewText3}</p>
								<a href={this.props.data.reviewLink3} target="_blank" rel="noopener noreferrer">
									<Button>{this.props.data.reviewButton3}</Button>
								</a>
							</div>
						)} */}
					</Collapse>
				</div>
			</Col>
		)
	}
}

export default ReviewsCard
