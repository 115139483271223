import { TextInput } from 'components/global/FormInputs'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import ReviewsCard from 'components/state-reviews/ReviewsCard'
import ReviewsResults from 'components/state-reviews/ReviewsResults'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import FlipMove from 'react-flip-move'

import LargeForm from '../components/forms/LargeForm'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

export default function StateReviewsPage() {
	let data = useStaticQuery<StateReviewQuery>(query).allContentfulStateReviewCard.edges
	const [search, setSearch] = useState('')

	let filter = data.filter((state) => {
		if (state.node.name.toLowerCase().includes(search)) {
			return true
		}
		return false
	})

	return (
		<Layout pageName="State Reviews">
			<Section flatGray>
				<Row className="items-center py-20">
					<Col width="w-full lg:w-7/12">
						<H1 className="mb-3">State & Independent Reviews</H1>
						<P>
							American Reading Company provides standards-aligned literacy solutions for schools and districts in all 50 states. Below you will find independent
							reports and reviews on ARC's solutions.
						</P>
					</Col>
					<Col width="w-full lg:w-5/12" className="hidden lg:block">
						<img src="/images/state-reviews/us-map.png" alt="us map" />
					</Col>
				</Row>
			</Section>
			<Section>
				<SectionTitle title="Search for Your State" />
				<Row center className="mb-16">
					<Col width="w-full md:w-1/2 lg:w-1/3">
						<div className="flex items-center text-slate-800">
							<TextInput
								search
								id="search"
								type="text"
								value={search}
								name="search"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					{/* @ts-ignore */}
					<FlipMove typeName={null}>
						{filter.length > 0 ? (
							filter.map((state) => <ReviewsCard data={state.node} key={state.node.id} />)
						) : (
							<Col>
								<P shade="darker" className="text-center font-bold">
									Sorry, no state matches that search
								</P>
							</Col>
						)}
					</FlipMove>
				</Row>
			</Section>
			<ReviewsResults />
			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="State Reviews" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export interface StateReview {
	id: string
	name: string
	image: {
		file: {
			url: string
		}
	}
	reviewText1: string
	reviewText2?: string
	reviewButton1: string
	reviewButton2?: string
	reviewLink1: string
	reviewLink2?: string
}

interface StateReviewQueryNode {
	node: StateReview
}

interface StateReviewQuery {
	allContentfulStateReviewCard: {
		edges: StateReviewQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulStateReviewCard(sort: { fields: name }) {
			edges {
				node {
					id
					name
					image {
						file {
							url
						}
					}
					reviewText1
					reviewText2
					reviewButton1
					reviewButton2
					reviewLink1
					reviewLink2
				}
			}
		}
	}
`
